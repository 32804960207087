import { dateAdd } from "../../util/helper";
import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  USER_DEVELOPER,
  UPDATE_USER,
  SET_REFRESH_TOKEN
} from "../../constants/ActionTypes";


const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  authUser: localStorage.getItem('user_id'),
  developer: null,
  refresh_token: null,
};

const AuthReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_USER_SUCCESS: {
      if (action != null && action.payload != null && action.payload.user != null) {
        action.payload.user.expires = dateAdd(new Date(), 'minute', 30);
      }
      return {
        ...state,
        loader: false,
        authUser: action.payload.user,
        developer: action.payload.developer,
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      //return null as any;
      return {
        ...state,
        authUser: null,
        developer: null,
        initURL: '/',
        loader: false,
        refresh_token: null,
      }
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }

    case SIGNIN_GOOGLE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_FACEBOOK_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_TWITTER_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_GITHUB_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case USER_DEVELOPER: {
      return {
        ...state,
        developer: action.payload
      }
    }
    case UPDATE_USER: {
      if (action != null && action.payload != null) {
        action.payload.expires = dateAdd(new Date(), 'minute', 30);
      }
      return {
        ...state,
        authUser: action.payload,
      }
    }
    case SET_REFRESH_TOKEN: {
      return {
        ...state,
        refresh_token: action.payload,
      }
    }
    default:
      return state;
  }
}

export default AuthReducer;