import createSagaMiddleware from "redux-saga";
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from "redux-devtools-extension";
import { createReduxHistoryContext, reachify } from "redux-first-history";
import { createWouterHook } from "redux-first-history/wouter";
import thunk from 'redux-thunk';
import rootSaga from "../sagas/index";
import createRootReducer from '../reducers';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const createBrowserHistory = require('history').createBrowserHistory;

const persistConfig = {
  key: 'root',
  storage,
}
const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});
const sagaMiddleware = createSagaMiddleware();

const middlewares = [thunk, sagaMiddleware];

const rootReducer = createRootReducer(routerReducer)

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(
  persistedReducer,
  {},
  process.env.NODE_ENV === 'production' ?
    applyMiddleware(
      routerMiddleware,
      ...middlewares
    ) :
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware,
        ...middlewares
      )
    )
);

sagaMiddleware.run(rootSaga, null);

export const history = createReduxHistory(store);
export const reachHistory = reachify(history);
export const wouterUseLocation = createWouterHook(history);
export type RootState = ReturnType<typeof rootReducer>;
export const persistor = persistStore(store);