import { store } from '../appRedux/store';
import { saveCurrencyRates } from '../appRedux/actions/Common';
// import { useDispatch, useSelector } from 'react-redux';

export const statusBackgroundColor = (status: string | null) => {
  if (status === '1') return '#d4f8e7';
  else if (status === '2') return '#fdf4d5';
  else if (status === '3') return '#feefe7';

  return 'transparent';
};

export const statusTextColor = (status: string | null) => {
  if (status === '1') return '#02331b';
  else if (status === '2') return '#5e4a03';
  else if (status === '3') return '#660303';

  return '#777';
};

export const getCurrencyRates = async () => {
  //const [rates] = useSelector((store: RootState) => store.common.currencyRates)
  const rates = store.getState().common.currencyRates;
  let diffMins = 0;
  if (rates != null) {
    const now = new Date();
    const expires = new Date(rates.expires);
    const diff = now.getTime() - expires.getTime();
    diffMins = Math.round(((diff % 86400000) % 3600000) / 60000);
  }
  if (rates == null || diffMins > 10) {
    //const newRates = await fetch("https://freecurrencyapi.net/api/v2/latest?apikey=07f430b0-9497-11ec-bb0d-15187a01907e&base_currency=EUR")
    if (process.env.REACT_APP_CURRENCY_API == null) return null;
    const newRates = await fetch(process.env.REACT_APP_CURRENCY_API)
      .then((res: any) => res.json())
      .then((data: any) => data.data);

    const nowPlusTenMinutes = new Date((new Date()).getTime() + 10 * 60000);
    newRates.expires = nowPlusTenMinutes;
    store.dispatch(saveCurrencyRates(newRates));
    return newRates;
  }
  return rates;
}

export const convertToEuro = async (value: number, userCurrency: string) => {
  const currencyRates = await getCurrencyRates();
  const base = Number(currencyRates[userCurrency].value);
  const result = value / base;
  return Math.round((result + Number.EPSILON) * 100) / 100;
}

export const convertToUserCurrency = async (value: number, userCurrency: string) => {
  const currencyRates = await getCurrencyRates();
  const base = Number(currencyRates[userCurrency].value);
  const result = value * base;
  return Math.round((result + Number.EPSILON) * 100) / 100;
}

export const dateAdd = (date: Date, interval: string, units: number) => {
  if(!(date instanceof Date))
    return undefined;
  let ret: any = new Date(date); //don't change original date
  const checkRollover = function() { if(ret.getDate() !== date.getDate()) ret.setDate(0);};
  switch(String(interval).toLowerCase()) {
    case 'year'   :  ret.setFullYear(ret.getFullYear() + units); checkRollover();  break;
    case 'quarter':  ret.setMonth(ret.getMonth() + 3*units); checkRollover();  break;
    case 'month'  :  ret.setMonth(ret.getMonth() + units); checkRollover();  break;
    case 'week'   :  ret.setDate(ret.getDate() + 7*units);  break;
    case 'day'    :  ret.setDate(ret.getDate() + units);  break;
    case 'hour'   :  ret.setTime(ret.getTime() + units*3600000);  break;
    case 'minute' :  ret.setTime(ret.getTime() + units*60000);  break;
    case 'second' :  ret.setTime(ret.getTime() + units*1000);  break;
    default       :  ret = undefined;  break;
  }
  return ret;
}
