import React from "react";
import { Provider } from 'react-redux'
import "./assets/vendors/style";
import "./styles/wieldy.less";
import { store, persistor } from './appRedux/store';
// import "./firebase/firebase";
// import App from "./containers/App/index";
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from "react-router-dom";
const App = React.lazy(() => import("./containers/App/index"));

const NextApp = () =>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter /*history={history}*/>
        <React.Suspense fallback={null}>
          <App />
        </React.Suspense>
      </BrowserRouter>
    </PersistGate>
  </Provider>;

export default NextApp;
